enum API {
	MENU = 'v1/menus',
	MENU_USERS = 'v1/users/menus',
	GUIDE = '/api/guides',
	GET_CHARTS = '/api/system/charts',
	DICT = 'v1/dictionary',
	UPDATE_PROFILE = '/api/profile',
	UPDATE_PASSWORD = '/v1/admins/password',
	MAIL_CONFIGS = '/v1/email/smtp-settings',
	MAIL_TEMPLATES = '/v1/email/templates'
}

export default API;
