import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

// INTERNAL TRANSACTIONS
const getInternalTransactions = async (params: any) => {
	const response = await axiosClient.get(api.TRANSACTIONS.INTERNAL_TRANSACTIONS, {params});
	return response.data;
};

const getInternalTransactionById = async (id: any) => {
	const response = await axiosClient.get(api.TRANSACTIONS.TRANSACTIONS + '/' + id);
	return response.data;
};

const createInternalTransaction = async (data: any) => {
	const response = await axiosClient.post(api.TRANSACTIONS.TRANSACTIONS, formData(data));
	return response.data;
};

const deleteInternalTransactions = async (data: any) => {
	const response = await axiosClient.delete(api.TRANSACTIONS.TRANSACTIONS, {data});
	return response.data;
};

const updateInternalTransaction = async (data: any, id: any) => {
	const response = await axiosClient.patch(api.TRANSACTIONS.TRANSACTIONS + '/' + id, data);
	return response.data;
};

const service = {
	getInternalTransactions,
	getInternalTransactionById,
	createInternalTransaction,
	deleteInternalTransactions,
	updateInternalTransaction
};

export default service;
