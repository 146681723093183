import axiosClient from 'utils/axiosClient';
import api from 'api';

const getEvents = async (params?: any) => {
	const response = await axiosClient.get(api.EVENTS.EVENTS, {params});
	return response.data;
};

const getEventById = async (id: any) => {
	const response = await axiosClient.get(api.EVENTS.EVENTS + '/' + id);
	return response.data;
};

// const createFAQ = async (data: any) => {
// 	const response = await axiosClient.post(api.FAQ.FAQ, data);
// 	return response.data;
// };

// const deleteFAQ = async (data: any) => {
// 	const response = await axiosClient.delete(api.FAQ.FAQ, {data});
// 	return response.data;
// };

// const updateFAQ = async (data: any, id: any) => {
// 	const response = await axiosClient.patch(api.FAQ.FAQ + '/' + id, data);
// 	return response.data;
// };

const service = {
	getEvents,
	getEventById
	// getFAQById,
	// createFAQ,
	// deleteFAQ,
	// updateFAQ
};

export default service;
