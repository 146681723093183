import {Badge, Breadcrumb, Dropdown, Layout, Menu, MenuProps} from 'antd';
import {AuthContext} from 'context/Auth.context';
import {NotificationsContext} from 'context/Notifications.context';
import {Bell, CaretDown, Gear, User} from 'phosphor-react';
import {useContext, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import './Header.less';
import {RouteProps} from 'router';
import hyIC from '../../../assets/icons/hy.png';
import enIC from '../../../assets/icons/en.png';
import i18n from 'utils/i18n';
import {SettingsContext} from 'context/Settings.context';
import SERVICES from 'services';

const {Header} = Layout;

const AppHeader = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const {t} = useTranslation();
	const {state: notificationsState} = useContext(NotificationsContext);
	const pathNames = location.pathname.split('/').filter(i => i);
	const {state: authState, dispatch: authDispatch} = useContext(AuthContext);

	const choosenPage = location.pathname;

	const handleLogout = () => {
		SERVICES.AUTH.logout().then(() => {
			authDispatch({
				type: 'LOGOUT'
			});
		});
	};

	let breadcrumbsElems = {
		2: '/users',
		3: '/users/list',
		4: '/users/privileges',
		5: '/users/payments',
		6: '/users/complaints',
		7: '/partners',
		8: '/partners/list',
		9: '/partners/drivers',
		10: '/partners/buses',
		12: '/partners/transferList',
		13: '/schedules',
		14: '/schedules/marches',
		15: '/schedules/stops',
		16: '/tickets',
		17: '/tickets/types',
		18: '/tickets/sold',
		19: '/carriers',
		20: '/validators',
		21: '/transactions',
		24: '/validators/list',
		25: '/validators/update',
		26: '/validators/logs',
		27: '/history',
		30: '/settings',
		40: '/transactions/validator',
		41: '/transactions/internal',
		99: '/profile'
	};

	const getMenuItems = (routes: RouteProps[], defPath = '', defNameMap = {}) => {
		const breadcrumbNameMap: any = defNameMap;

		routes.map(item => {
			let path = /* defPath + */ item.path;
			// @ts-ignore
			breadcrumbNameMap[breadcrumbsElems[item.id]] = item[i18n.language];

			if (item.children && item.children.length) {
				getMenuItems(item.children, path, breadcrumbNameMap);
			}
		});

		const extraBreadcrumbItems = pathNames.map((_, index) => {
			const url = `/${pathNames.slice(0, index + 1).join('/')}`;
			return (
				<Breadcrumb.Item key={url}>
					<span>{breadcrumbNameMap[url]}</span>
				</Breadcrumb.Item>
			);
		});

		const breadcrumbItems: any = [
			<Breadcrumb.Item key="/home">
				<span>{t('main')}</span>
			</Breadcrumb.Item>
		].concat(extraBreadcrumbItems);

		return breadcrumbItems;
	};

	const {state: settingsState} = useContext(SettingsContext);

	const breadcrumbItems = useMemo(() => {
		if (location.pathname === '/settings') {
			return [<Breadcrumb.Item key="settings">{t('settings')}</Breadcrumb.Item>];
		}
		return getMenuItems(settingsState.menuItems, '');
	}, [location.pathname, i18n.language]);
	const LANGUAGES = {
		hy: hyIC,
		en: enIC
	};

	interface IMenuProps {
		label: string;
		key: string;
		icon: React.ReactNode;
	}

	const items: IMenuProps[] = [
		{
			label: 'ՀԱՅ',
			key: 'hy',
			icon: <img src={hyIC} alt="" width="20px" />
		},
		{
			label: 'ENG',
			key: 'en',
			icon: <img src={enIC} alt="" width="20px" />
		}
	];

	const handleMenuClick: MenuProps['onClick'] = e => {
		let changeableItem = items.filter((el: IMenuProps) => el.key !== i18n.language);
		i18n.changeLanguage(changeableItem[0].key);
		localStorage.setItem('language', e.key);
	};

	return (
		<>
			<Header className="header">
				<Breadcrumb>{breadcrumbItems}</Breadcrumb>
				<div className="right-actions">
					<Dropdown
						trigger={['click']}
						placement="bottom"
						overlay={() => (
							<Menu className="lang-dropdown">
								{items
									.filter((el: IMenuProps) => el.key !== i18n.language)
									.map(elem => {
										return (
											<Menu.Item key={elem.key} onClick={handleMenuClick}>
												{elem.label}
											</Menu.Item>
										);
									})}
							</Menu>
						)}>
						<a className="lang-link" onClick={e => e.preventDefault()}>
							<div className="lang-ico">
								<img
									src={LANGUAGES[i18n.language as 'hy' | 'en']}
									width="20px"
									alt={i18n.language}
								/>
							</div>
						</a>
					</Dropdown>
					{notificationsState.studentsWithoutGroupCount ||
					notificationsState.studentsWithoutFlowCount ||
					notificationsState.unconfirmedExamMarksCount ||
					notificationsState.groupsWithoutFlowCount ? (
						<Dropdown
							trigger={['click']}
							overlay={() => (
								<Menu className="notification-dropdown">
									{notificationsState.studentsWithoutGroupCount ? (
										<Menu.Item
											key="groupCount"
											onClick={() => {
												navigate('/students/main', {
													state: {notAttachedStudentsType: 1}
												});
											}}>
											<div>{t('studentsWithoutGroup')} - </div>
											<div>{notificationsState.studentsWithoutGroupCount}</div>
										</Menu.Item>
									) : null}

									{notificationsState.studentsWithoutFlowCount ? (
										<Menu.Item
											key="flowCount"
											onClick={() => {
												navigate('/students/main', {
													state: {notAttachedStudentsType: 2}
												});
											}}>
											<div>{t('studentsWithoutFlow')} - </div>
											<div>{notificationsState.studentsWithoutFlowCount}</div>
										</Menu.Item>
									) : null}

									{notificationsState.unconfirmedExamMarksCount ? (
										<Menu.Item
											key="examMarkCount"
											onClick={() => {
												navigate('/exams/marks', {
													state: {unconfirmedExamMarks: true}
												});
											}}>
											<div>{t('examMarksConfirm')} - </div>
											<div>{notificationsState.unconfirmedExamMarksCount}</div>
										</Menu.Item>
									) : null}

									{notificationsState.groupsWithoutFlowCount ? (
										<Menu.Item
											key="examMarkCount"
											onClick={() => {
												navigate('/curriculum/groups', {
													state: {groupIDs: notificationsState.groupsWithoutFlowIDs}
												});
											}}>
											<div>{t('groupsWithoutFlow')} - </div>
											<div>{notificationsState.groupsWithoutFlowCount}</div>
										</Menu.Item>
									) : null}
								</Menu>
							)}>
							<Badge
								count={
									notificationsState.studentsWithoutGroupCount +
									notificationsState.studentsWithoutFlowCount +
									notificationsState.unconfirmedExamMarksCount +
									notificationsState.groupsWithoutFlowCount
								}
								overflowCount={9999}
								style={{cursor: 'pointer'}}>
								<Bell
									weight="regular"
									className="notifications-bell"
									size={24}
									to={'/settings'}
								/>
							</Badge>
						</Dropdown>
					) : (
						<Link
							to={'/settings#notifications'}
							className="notifications-bell"
							onClick={() => {
								localStorage.setItem('"settingsPage"', '"notifications"');
							}}>
							<Bell weight="regular" size={24} />
						</Link>
					)}
					<Gear
						size={24}
						weight="regular"
						onClick={() => navigate('/settings')}
						style={{cursor: 'pointer'}}
					/>
					<Dropdown
						trigger={['click']}
						overlay={() => (
							<Menu
								className="profile-dropdown"
								defaultSelectedKeys={[choosenPage]}
								selectedKeys={[choosenPage]}>
								<Menu.Item
									key={'/profile'}
									onClick={() => {
										navigate('/profile');
									}}>
									{t('profile')}
								</Menu.Item>
								<Menu.Item key="2">
									<a onClick={handleLogout}>{t('logout')}</a>
								</Menu.Item>
							</Menu>
						)}>
						<a onClick={e => e.preventDefault()} className="profile-link">
							<User size={24} weight="regular" />
							<CaretDown style={{marginLeft: 10}} size={16} weight="fill" />
						</a>
					</Dropdown>
				</div>
			</Header>
		</>
	);
};

export default AppHeader;
